import { history } from "instantsearch.js/es/lib/routers";
export interface SearchStateChangeParams {
  uiState: Record<string, any>;
  currentPath?: string;
  router: ReturnType<typeof history>;
}
export function handleSearchStateChange({ uiState, currentPath = "", router }: SearchStateChangeParams) {
  const url = router.createURL(uiState);
  const params = url?.split("?")[1];
  if (!uiState?.posts && !uiState?.sam_entities) {
    return null;
  }
  if (currentPath?.includes("companies")) {
    return `/companies/search?${params}`;
  }
  if (currentPath?.includes("reports")) {
    return `/reports?${params}`;
  }
  if (!currentPath?.includes("search_v2")) {
    return `/search_v2?${params}`;
  }
  if (!currentPath?.includes("agencies")) {
    return `/agencies/search?${params}`;
  }
  return null;
}
