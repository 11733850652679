import { ClockIcon, MagnifyingGlassIcon, Cross2Icon } from "@radix-ui/react-icons";
import { useNavigate } from "@remix-run/react";
import { motion, AnimatePresence } from "framer-motion";
import { useRef, useState, useEffect, KeyboardEvent } from "react";
import { Box, Button, Flex, Text, TextInput } from "sparta";
import { ActionIcon } from "../ActionIcon";
import { useHistoryTracker, HistoryItem } from "~/utils/hooks/useHistoryTracker";
import { useRootLoaderData } from "~/root";
import "./HistoryDropdown.css";

/**
 * HistoryDropdown component displays a dropdown of user's recent navigation history.
 * 
 * Features:
 * - Shows recent pages visited
 * - Allows searching through history
 * - Keyboard navigation support
 * - Highlights new activity
 * - Conditionally renders based on feature flag
 * 
 * @returns {JSX.Element | null} The HistoryDropdown component or null if feature is disabled
 */
export function HistoryDropdown() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const listItemsRef = useRef<(HTMLDivElement | null)[]>([]);
  const navigate = useNavigate();
  
  // Get feature flag from root loader data
  const { canSeeRecentActivity } = useRootLoaderData();
  
  // Use our history tracker hook, but only if feature is enabled
  const { history = [], clearHistory = () => {} } = canSeeRecentActivity 
    ? useHistoryTracker() 
    : { history: [], clearHistory: () => {} };

  /**
   * Filters history items based on the current search query
   */
  const filteredHistory = searchQuery.trim() === "" 
    ? history 
    : history.filter(item => 
        item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.path.toLowerCase().includes(searchQuery.toLowerCase())
      );

  // Reset selected index when filtered results change
  useEffect(() => {
    setSelectedIndex(-1);
  }, [filteredHistory]);

  // Auto focus search input when dropdown opens
  useEffect(() => {
    if (showDropdown && searchInputRef.current) {
      // Small timeout to ensure the dropdown is fully rendered
      setTimeout(() => {
        searchInputRef.current?.focus();
      }, 100);
    }
  }, [showDropdown]);

  // Scroll selected item into view
  useEffect(() => {
    if (selectedIndex >= 0 && listItemsRef.current[selectedIndex]) {
      listItemsRef.current[selectedIndex]?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  }, [selectedIndex]);

  // Close dropdown when clicking outside
  useEffect(() => {
    /**
     * Handles clicks outside the dropdown to close it
     * @param {MouseEvent} event - The mouse event
     */
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  /**
   * Formats a timestamp to a human-readable relative time format
   * @param {number} timestamp - The timestamp in milliseconds
   * @returns {string} Human-readable relative time (e.g., "Just now", "5m ago")
   */
  const formatTimestamp = (timestamp: number): string => {
    const now = Date.now();
    const diff = now - timestamp;
    
    if (diff < 60000) return 'Just now';
    if (diff < 3600000) return `${Math.floor(diff / 60000)}m ago`;
    if (diff < 86400000) return `${Math.floor(diff / 3600000)}h ago`;
    return `${Math.floor(diff / 86400000)}d ago`;
  };

  /**
   * Navigates to the selected history item's path and closes the dropdown
   * @param {string} path - The path to navigate to
   */
  const navigateToHistoryItem = (path: string) => {
    setShowDropdown(false);
    navigate(path);
  };

  /**
   * Resets search query and closes the dropdown
   */
  const handleCloseDropdown = () => {
    setSearchQuery("");
    setShowDropdown(false);
  };

  /**
   * Clears the search query and focuses back on the search input
   */
  const clearSearch = () => {
    setSearchQuery("");
    searchInputRef.current?.focus();
  };

  /**
   * Handles keyboard navigation within the dropdown
   * @param {KeyboardEvent<HTMLInputElement>} e - The keyboard event
   */
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // Only process if there are items to navigate
    if (filteredHistory.length === 0) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault(); // Prevent scrolling the page
        setSelectedIndex(prev => 
          prev < filteredHistory.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault(); // Prevent scrolling the page
        setSelectedIndex(prev => prev > 0 ? prev - 1 : 0);
        break;
      case 'Enter':
        if (selectedIndex >= 0 && selectedIndex < filteredHistory.length) {
          navigateToHistoryItem(filteredHistory[selectedIndex].path);
        }
        break;
      case 'Escape':
        e.preventDefault();
        if (searchQuery) {
          clearSearch();
        } else {
          handleCloseDropdown();
        }
        break;
    }
  };

  /**
   * Highlights text that matches the search query in search results
   * @param {string} text - The text to search in
   * @param {string} query - The search query to highlight
   * @returns {React.ReactNode} Text with highlighted matches
   */
  const highlightMatch = (text: string, query: string): React.ReactNode => {
    if (!query.trim()) return text;
    
    const regex = new RegExp(`(${query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi');
    const parts = text.split(regex);
    
    return parts.map((part, i) => 
      regex.test(part) ? (
        <span key={i} className="history-highlight">{part}</span>
      ) : (
        part
      )
    );
  };

  /**
   * Checks if there are any recent items (within last 5 minutes)
   */
  const hasRecentItems = history.some(item => item.timestamp > Date.now() - 300000);

  // Animation variants for the dropdown
  const dropdownVariants = {
    hidden: { 
      opacity: 0,
      y: -10,
      scale: 0.95,
      transition: {
        duration: 0.2
      }
    },
    visible: { 
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        duration: 0.2
      }
    }
  };

  // Reset refs array when filtered history changes
  useEffect(() => {
    listItemsRef.current = listItemsRef.current.slice(0, filteredHistory.length);
  }, [filteredHistory]);

  // If feature is disabled, don't render anything
  if (!canSeeRecentActivity) {
    return null;
  }

  return (
    <div ref={dropdownRef} style={{ position: "relative" }}>
      <div className={hasRecentItems ? "history-icon-pulse" : ""} style={{ position: "relative" }}>
        <ActionIcon 
          onClick={() => setShowDropdown(!showDropdown)}
          aria-label="Recent activity"
        >
          <ClockIcon />
          {hasRecentItems && (
            <span className="history-badge"></span>
          )}
        </ActionIcon>
      </div>
      <AnimatePresence>
        {showDropdown && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={dropdownVariants}
            style={{
              position: "absolute",
              top: "100%",
              right: 0,
              width: "300px",
              backgroundColor: "var(--color-background)",
              borderRadius: "8px",
              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.15)",
              zIndex: 1000,
              overflow: "hidden",
              border: "1px solid var(--gray-a6)",
              marginTop: "8px"
            }}
          >
            <Flex direction="column" width="100%">
              <Box p="3" style={{ borderBottom: "1px solid var(--gray-a6)" }}>
                <Flex justify="between" align="center" mb="2">
                  <Box>
                    <Text style={{ fontSize: "16px", fontWeight: 600 }}>Recent Activity</Text>
                  </Box>
                  {history.length > 0 && (
                    <Button 
                      size="1" 
                      variant="ghost" 
                      onClick={clearHistory}
                      style={{ color: "var(--gray-11)" }}
                    >
                      Clear
                    </Button>
                  )}
                </Flex>
                
                {/* Search Input */}
                <TextInput
                  ref={searchInputRef}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.currentTarget.value)}
                  onKeyDown={handleKeyDown}
                  placeholder="Search history..."
                  leftSection={<MagnifyingGlassIcon />}
                  rightSection={
                    searchQuery ? (
                      <Box 
                        style={{ cursor: 'pointer' }} 
                        onClick={clearSearch}
                      >
                        <Cross2Icon />
                      </Box>
                    ) : null
                  }
                  style={{ width: "100%" }}
                  className="history-search-input"
                />
                
                {/* Keyboard shortcut hint */}
                {filteredHistory.length > 0 && (
                  <Flex justify="center" mt="1">
                    <Text style={{ fontSize: "11px", color: "var(--gray-11)" }}>
                      Use ↑↓ to navigate, Enter to select
                    </Text>
                  </Flex>
                )}
              </Box>
              <Box className="history-scroll" style={{ maxHeight: "320px", overflowY: "auto" }}>
                {filteredHistory.length > 0 ? (
                  filteredHistory.map((item, index) => (
                    <Flex
                      key={index}
                      ref={el => listItemsRef.current[index] = el}
                      p="2"
                      align="center"
                      onClick={() => navigateToHistoryItem(item.path)}
                      style={{
                        cursor: "pointer",
                        transition: "background-color 0.2s",
                        borderBottom: index < filteredHistory.length - 1 ? "1px solid var(--gray-a4)" : "none",
                        backgroundColor: index === selectedIndex ? 'var(--gray-a4)' : undefined
                      }}
                      className="history-item"
                      onMouseEnter={() => setSelectedIndex(index)}
                    >
                      <Flex 
                        justify="center" 
                        align="center" 
                        style={{ 
                          width: "36px", 
                          height: "36px", 
                          borderRadius: "8px",
                          backgroundColor: "var(--gray-a4)",
                          marginRight: "12px",
                          flexShrink: 0
                        }}
                        className="history-item-icon"
                      >
                        <Text>{item.icon}</Text>
                      </Flex>
                      <Flex direction="column" style={{ flex: 1, overflow: "hidden" }}>
                        <Text 
                          style={{ 
                            fontWeight: 500, 
                            fontSize: "14px", 
                            whiteSpace: "nowrap", 
                            overflow: "hidden", 
                            textOverflow: "ellipsis" 
                          }}
                        >
                          {searchQuery ? highlightMatch(item.title, searchQuery) : item.title}
                        </Text>
                        <Text style={{ fontSize: "12px", color: "var(--gray-11)" }}>
                          {formatTimestamp(item.timestamp)}
                        </Text>
                      </Flex>
                      {item.timestamp > Date.now() - 300000 && (
                        <Box 
                          style={{ 
                            width: "8px", 
                            height: "8px", 
                            borderRadius: "50%", 
                            backgroundColor: "var(--blue-9)",
                            marginLeft: "8px"
                          }}
                        />
                      )}
                    </Flex>
                  ))
                ) : searchQuery ? (
                  <Flex 
                    p="4" 
                    justify="center" 
                    align="center" 
                    direction="column" 
                    gap="2"
                    className="empty-history-state"
                  >
                    <MagnifyingGlassIcon width={24} height={24} />
                    <Text style={{ color: "var(--gray-11)", fontSize: "14px", textAlign: "center" }}>
                      No results for "{searchQuery}"
                    </Text>
                    <Button 
                      size="1" 
                      variant="ghost"
                      onClick={clearSearch}
                    >
                      Clear search
                    </Button>
                  </Flex>
                ) : (
                  <Flex 
                    p="4" 
                    justify="center" 
                    align="center" 
                    direction="column" 
                    gap="2"
                    className="empty-history-state"
                  >
                    <Box 
                      style={{ 
                        width: "48px", 
                        height: "48px", 
                        borderRadius: "50%", 
                        backgroundColor: "var(--gray-a4)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <ClockIcon width={24} height={24} />
                    </Box>
                    <Text style={{ color: "var(--gray-11)", fontSize: "14px", textAlign: "center" }}>
                      No recent activity
                    </Text>
                  </Flex>
                )}
              </Box>
            </Flex>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
} 