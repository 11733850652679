interface NaicsSEOProps {
  loaderData: {
    naicsData: any;
    naicsCode: any;
  };
}

export default function NaicsSEO({ loaderData: { naicsCode, naicsData } }: NaicsSEOProps) {
  const isBrowser = typeof window !== "undefined";
  const isLocal = isBrowser && window.location.hostname.includes("localhost");
  //const baseUrl = isLocal ? "http://localhost:3000" : "https://app.g2xchange.com";
  const additionalType = "https://www.census.gov/naics/";
  const sameAs = [
    "https://www.sba.gov/federal-contracting/contracting-guide/size-standards",
    "https://sam.gov/content/naics-list",
  ];
  const url = `https://www.census.gov/naics/?input=${naicsCode}&year=2022`;

  // additional properties
  const subjectOf = [
    {
      "@type": "GovernmentService",
      name: "Small Business Size Standards",
      provider: {
        "@type": "GovernmentOrganization",
        name: "Small Business Administration",
      },
      serviceOutput: {
        "@type": "PropertyValue",
        propertyID: "Size Standard",
        unitText: "USD",
        value: "Size standard in annual receipts or number of employees",
      },
    },
  ];
  const inDefinedTermSet = {
    "@type": "DefinedTermSet",
    name: "North American Industry Classification System",
    url: "https://www.census.gov/naics/",
  };

  const identifier = {
    "@type": "PropertyValue",
    propertyID: "NAICS",
    value: naicsCode,
  };

  const mainEntityOfPage = {
    "@type": "WebPage",
    url: url,
  };

  const isPartOf = {
    "@type": "DefinedTermSet",
    name: "2022 NAICS Manual",
    url: "https://www.census.gov/naics/?year=2022",
  };

  const potentialAction = {
    "@type": "FindAction",
    name: "Find Contract Opportunities",
    target: {
      "@type": "EntryPoint",
      urlTemplate: `https://sam.gov/search/?index=opp&page=1&naics=${naicsCode}`,
      actionPlatform: ["http://schema.org/DesktopWebPlatform", "http://schema.org/MobileWebPlatform"],
    },
  };
  const industryStatistics = {
    "@type": "Dataset",
    name: "Industry Statistics for this NAICS Code",
    description: "Statistical data about the industry represented by this NAICS code",
    url: "https://data.census.gov/",
    includedInDataCatalog: {
      "@type": "DataCatalog",
      name: "United States Census Bureau",
    },
  };
  // Now building JSON-LD object
  const scriptData = {
    "@context": "https://schema.org",
    "@type": "DefinedTerm",
    termCode: naicsData?.code,
    name: naicsData?.label,
    description: naicsData?.description,
    inDefinedTermSet,
    identifier,
    additionalType,
    url,
    sameAs,
    mainEntityOfPage,
    isPartOf,
    subjectOf,
    potentialAction,
    industryStatistics,
  };

  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(scriptData) }} />;
}
