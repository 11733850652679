import React from "react";

interface OpportunitySEOProps {
  loaderData: {
    opportunity: any;
  };
}

export default function OpportunitySEO({ loaderData: { opportunity } }: OpportunitySEOProps) {
  const isBrowser = typeof window !== "undefined";
  const isLocal = isBrowser && window.location.hostname.includes("localhost");
  const baseUrl = isLocal ? "http://localhost:3000" : "https://app.g2xchange.com";

  const url = `${baseUrl}/opportunities/${opportunity?.opportunity_id || ""}`;

  // Extract address data if available
  const providerAddress = {
    "@type": "PostalAddress",
    addressLocality: opportunity?.address?.city || "",
    addressRegion: opportunity?.address?.state || "",
    postalCode: opportunity?.address?.postalCode || "",
    streetAddress: opportunity?.address?.streetAddress || "",
    addressCountry: opportunity?.address?.country || "US"
  };

  // Extract contact information
  const contactPoint = opportunity?.primary_contact ? {
    "@type": "ContactPoint",
    email: opportunity.primary_contact.email || "",
    telephone: opportunity.primary_contact.phone || ""
  } : null;

  // Generate keywords based on available data
  const keywords = [
    opportunity?.title,
    opportunity?.agency?.name,
    opportunity?.department?.name,
    opportunity?.notice_type,
    "Government Contract",
    opportunity?.set_aside?.code,
    opportunity?.naics_code,
    opportunity?.solicitation_number,
    "SAM.gov"
  ].filter(Boolean);

  // Build the JSON-LD object
  const scriptData = {
    "@context": "https://schema.org/",
    "@type": "GovernmentService",
    "name": "Federal Contract Opportunity",
    "description": opportunity?.description || "No description available",
    "url": url,
    "provider": {
      "@type": "GovernmentOrganization",
      "name": opportunity?.agency?.name || opportunity?.department?.name || "",
      "address": providerAddress,
      ...(contactPoint && { "email": contactPoint.email }),
      ...(contactPoint && { "telephone": contactPoint.telephone }),
      "url": opportunity?.sam_url || "",
      "sameAs": "https://sam.gov/"
    },
    "serviceType": opportunity?.title || "",
    "audience": {
      "@type": "Audience",
      "audienceType": opportunity?.set_aside?.name || "All responsible sources may submit an offer"
    },
    "offers": {
      "@type": "Offer",
      "availabilityStarts": opportunity?.first_notice_date || "",
      "availabilityEnds": opportunity?.response_deadline || ""
    },
    "isAccessibleForFree": true,
    "termsOfService": "https://app.g2xchange.com/legal/community-member-guidelines/",
    "identifier": {
      "@type": "PropertyValue",
      "name": "SAM.gov Opportunity Solicitation Number",
      "value": opportunity?.solicitation_number || ""
    },
    "specialty": {
      "@type": "DefinedTerm",
      "termCode": opportunity?.psc_code || "",
      "name": opportunity?.psc_description || "Government Service"
    },
    "applicationDeadline": opportunity?.response_deadline || "",
    "keywords": keywords,
    "category": {
      "@type": "DefinedTerm",
      "inDefinedTermSet": `https://app.g2xchange.com/naics/${opportunity?.naics_code || ""}`,
      "termCode": opportunity?.naics_code || "",
      "name": opportunity?.naics_description || ""
    },
    "contractType": {
      "@type": "DefinedTerm",
      "name": opportunity?.award_type || "Government Contract"
    },
    "location": opportunity?.place_of_performance ? {
      "@type": "Place",
      "address": {
        "@type": "PostalAddress",
        "addressLocality": opportunity.place_of_performance.city || "",
        "addressRegion": opportunity.place_of_performance.state || "",
        "postalCode": opportunity.place_of_performance.zip || "",
        "streetAddress": opportunity.place_of_performance.street_address || "",
        "addressCountry": opportunity.place_of_performance.country || "US"
      }
    } : null,
    "datePosted": opportunity?.first_notice_date || "",
    "dateModified": opportunity?.last_notice_date || ""
  };

  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(scriptData) }} />;
} 