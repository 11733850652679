import { useEffect } from 'react';
import { loadScript } from '~/utils/scriptLoader';

const CLEARBIT_SCRIPT_ID = 'clearbit-tags';
const CLEARBIT_SCRIPT_URL = 'https://tags.clearbit.com/v1/tags.js';

export function ClearbitIntegration() {
  useEffect(() => {
    loadScript(CLEARBIT_SCRIPT_URL, CLEARBIT_SCRIPT_ID).catch((error) => {
      console.error('Failed to load Clearbit script:', error);
    });
  }, []);

  return null;
}