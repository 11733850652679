import { ScrollArea, Toaster, useAppearance } from "sparta";
import { SideNavContextProvider } from "~/context/SideNavContext";
import { SideNav } from "./SideNav/SideNav";
import { TopNav } from "./TopNav/TopNav";

interface AppLayoutProps {
  children: React.ReactNode;
}

export function AppLayout({ children }: AppLayoutProps) {
  const { theme } = useAppearance();

  return (
    <SideNavContextProvider>
      <Toaster richColors position="bottom-left" theme={theme as "dark" | "light"} />
      <SideNav>
        <TopNav>
          <ScrollArea
            id="main-scroll"
            type="auto"
            size="2"
            style={{
              maxHeight: "calc(100vh - 40px)",
              height: "calc(100vh - 40px)",
              maxWidth: "100%",
              overflowX: "hidden",
              width: "100%",
            }}
          >
            <div style={{ width: "100%", maxWidth: "100%", overflowX: "hidden" }}>{children}</div>
          </ScrollArea>
        </TopNav>
      </SideNav>
    </SideNavContextProvider>
  );
}
