import {
  CalendarIcon,
  Cross1Icon,
  CrumpledPaperIcon,
  FileTextIcon,
  HomeIcon,
  IdCardIcon,
  MagnifyingGlassIcon,
  MixIcon,
  StarIcon,
} from "@radix-ui/react-icons";
import { Link } from "@remix-run/react";
import { Badge, Box, Flex, IconButton } from "sparta";
import { useMediaQuery } from "~/components/search/hooks/useMediaQuery";
import { formatDataDate } from "~/components/shared/SearchFilters";
import { useSideNav } from "~/context/SideNavContext";
import {
  CompaniesIcon,
  DefenseIcon,
  Doge,
  FedCivIcon,
  G2XLogo,
  GovAIQ,
  HealthIcon,
  OpportunitiesIcon,
  PeopleIcon,
  Pipeline,
} from "~/icons";
import { useRootLoaderData } from "~/root";
import { useTracking } from "~/utils/featureFlags.client";
import { CollapsibleSection } from "./CollapsibleSection";
import { MenuButton } from "./MenuButton";
import { ResponsiveSideNavContainer } from "./ResponsiveSideNavContainer";

// Beta Badge Component
const BetaBadge = ({ active = false }: { active?: boolean }) => (
  <Badge
    style={{
      fontSize: "9px",
      padding: "2px 4px",
    }}
    ml="1"
    radius="large"
    size="1"
    variant={active ? "solid" : "soft"} // Conditionally set variant
    className="beta-badge"
    color="iris"
  >
    BETA
  </Badge>
);

interface SideNavProps {
  children: React.ReactNode;
}

export function SideNav({ children }: SideNavProps) {
  const {
    canSeeAwards,
    canSeePipelines,
    canSeeGlobalSearch,
    canSeeDogeTracker,
    canSeeNAICS,
    canSeePSC,
    canSeeAgencies,
  } = useRootLoaderData();
  const { open, setOpen } = useSideNav(); // Access open state and setter
  const isMobile = useMediaQuery("(max-width: 760px)");
  const track = useTracking();

  const handleNavigationTrack = (destination: string) => {
    track.buttonClick(`side-nav-${destination}`, {
      component: "side-nav",
      destination: destination,
    });
  };

  return (
    <Flex gap="0">
      <ResponsiveSideNavContainer>
        <Flex
          direction="column"
          width="190px"
          p="3"
          style={{
            borderRight: "1px solid var(--gray-a6)",
            height: "100%",
            maxHeight: "100vh",
            overflow: "hidden",
          }}
        >
          <Box position="relative">
            <Link to="/" prefetch="intent" className="G2X-logo">
              <G2XLogo />
            </Link>
            {isMobile && open && (
              <IconButton
                onClick={() => setOpen(false)}
                variant="ghost"
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  margin: "8px",
                }}
              >
                <Cross1Icon />
              </IconButton>
            )}
          </Box>
          <Flex
            direction="column"
            gap="4"
            style={{
              paddingBottom: "80px", // Add padding at the bottom to ensure the last items are visible
              height: "100%",
              WebkitOverflowScrolling: "touch", // For smooth scrolling on iOS
            }}
            className="sidenav-scrollable-content"
          >
            <CollapsibleSection>
              <MenuButton icon={<HomeIcon />} title="Home" to="/" />
              <MenuButton icon={<CalendarIcon />} title="Events" to="/events" />
              {!canSeeGlobalSearch ? (
                <MenuButton icon={<MagnifyingGlassIcon />} title="Search" to="/search_v2" />
              ) : null}
            </CollapsibleSection>
            <CollapsibleSection title={canSeeGlobalSearch ? "Curated Intel" : "Daily Take"}>
              {canSeeGlobalSearch ? (
                <MenuButton icon={<MagnifyingGlassIcon />} title="Browse All" to="/search_v2" />
              ) : null}
              <MenuButton icon={<HealthIcon />} title="Health" to="/health" />
              <MenuButton icon={<FedCivIcon />} title="FedCiv" to="/fedciv" />
              <MenuButton icon={<DefenseIcon />} title="Defense" to="/defense" />
            </CollapsibleSection>
            <CollapsibleSection title="Intelligence">
              <MenuButton icon={<FileTextIcon />} title="Analyst Reports" to="/reports" />
              <MenuButton
                icon={<OpportunitiesIcon />}
                title={(active) => (
                  <>
                    Opportunities <BetaBadge active={active} />
                  </>
                )}
                to={`/opportunities?ordering=-last_notice_date&response_deadline_after=${formatDataDate(new Date())}`}
                onClick={() => handleNavigationTrack("opportunities")}
              />
              {canSeeDogeTracker ? <MenuButton icon={<Doge />} title="DOGE Tracker" to="/doge-tracker" /> : null}
              {canSeeAwards ? (
                <CollapsibleSection
                  isOpen={true}
                  variant="simple"
                  collapsable
                  title="Awards"
                  rightHeaderIcon={<StarIcon />}
                >
                  <MenuButton
                    icon={<Flex width="10px" />}
                    title={(active) => (
                      <>
                        Contracts <BetaBadge active={active} />
                      </>
                    )}
                    to="/awards/contracts"
                  />
                </CollapsibleSection>
              ) : null}
              {canSeeNAICS ? (
                <MenuButton
                  icon={<CrumpledPaperIcon />}
                  title={(active) => (
                    <>
                      NAICS <BetaBadge active={active} />
                    </>
                  )}
                  to="/naics"
                />
              ) : null}
              {canSeePSC ? (
                <MenuButton
                  icon={<MixIcon />}
                  title={(active) => (
                    <>
                      PSC <BetaBadge active={active} />
                    </>
                  )}
                  to="/psc"
                />
              ) : null}
            </CollapsibleSection>

            <CollapsibleSection title="Directory">
              <MenuButton icon={<PeopleIcon />} title="People" to="/people" />
              <MenuButton
                icon={<CompaniesIcon />}
                title="Companies"
                to="/companies"
                match={["/companies", "/companies/search"]}
                onClick={() => handleNavigationTrack("gcd")}
              />
              {canSeeAgencies ? (
                <MenuButton
                  icon={<IdCardIcon />}
                  title={(active) => (
                    <>
                      Agencies <BetaBadge active={active} />
                    </>
                  )}
                  to="/agencies"
                />
              ) : null}
            </CollapsibleSection>
            <CollapsibleSection title="TOOLS">
              <MenuButton
                newTab={true}
                icon={<GovAIQ />}
                title={
                  <>
                    Proposals <BetaBadge />
                  </>
                }
                to="https://govaiq.com/login"
                onClick={() => handleNavigationTrack("govaiq")}
              />
              {canSeePipelines && (
                <MenuButton
                  icon={<Pipeline />}
                  title={(active) => (
                    <>
                      Pipelines <BetaBadge active={active} />
                    </>
                  )}
                  to="/pipelines"
                  onClick={() => handleNavigationTrack("pipelines")}
                />
              )}
            </CollapsibleSection>
          </Flex>
        </Flex>
      </ResponsiveSideNavContainer>
      {children}
    </Flex>
  );
}
