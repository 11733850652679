import { useNavigation } from "@remix-run/react";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";

export function ProgressBar() {
  const navigation = useNavigation();
  const [progress, setProgress] = useState(0);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // When navigation starts
    if (navigation.state === "loading") {
      setVisible(true);
      setProgress(10); // Start with small progress

      // Simulate progress
      const timer1 = setTimeout(() => setProgress(30), 100);
      const timer2 = setTimeout(() => setProgress(50), 300);
      const timer3 = setTimeout(() => setProgress(70), 600);
      const timer4 = setTimeout(() => setProgress(90), 1000);

      return () => {
        clearTimeout(timer1);
        clearTimeout(timer2);
        clearTimeout(timer3);
        clearTimeout(timer4);
      };
    }

    // When navigation completes
    if (navigation.state === "idle" && visible) {
      setProgress(100);
      const timer = setTimeout(() => {
        setVisible(false);
        setProgress(0);
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [navigation.state, visible]);

  if (!visible && progress === 0) return null;

  return (
    <div style={{ 
      position: "fixed", 
      top: 0, 
      left: 0, 
      right: 0, 
      height: "3px", 
      zIndex: 9999,
      pointerEvents: "none",
    }}>
      <motion.div
        style={{
          height: "100%",
          background: "var(--accent-9)",
          transformOrigin: "left",
        }}
        initial={{ width: `${progress}%` }}
        animate={{ width: `${progress}%` }}
        transition={{ duration: 0.2 }}
      />
    </div>
  );
} 