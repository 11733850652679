import { Link, Links, Scripts } from "@remix-run/react";
import { Flex, Heading, Button, Box, Text } from "sparta";
import WebBlock from "../layout/WebBlock";
import { G2XLogo } from "~/icons";
import { useEffect } from "react";

interface IServerError {
  caught: any;
}

export default function ServerError({ caught }: IServerError) {
  const handleTryAgain = () => {
    window.location.reload();
  };

  const is404 = caught.status === 404;
  
  // Define animation keyframes styles to add to the document head
  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
      @keyframes pulse {
        0% {
          transform: scale(1);
          box-shadow: 0 0 0 0 ${is404 ? 'rgba(255, 183, 77, 0.4)' : 'rgba(74, 122, 255, 0.4)'};
        }
        
        50% {
          transform: scale(1.05);
          box-shadow: 0 0 0 10px ${is404 ? 'rgba(255, 183, 77, 0)' : 'rgba(74, 122, 255, 0)'};
        }
        
        100% {
          transform: scale(1);
          box-shadow: 0 0 0 0 ${is404 ? 'rgba(255, 183, 77, 0)' : 'rgba(74, 122, 255, 0)'};
        }
      }
    `;
    document.head.appendChild(styleElement);
    
    return () => {
      document.head.removeChild(styleElement);
    };
  }, [is404]);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <title>{is404 ? "Page Not Found" : "Error"}</title>
        <Links />
        <style>
          {`
            .pulse-animation {
              animation: pulse 2s infinite ease-in-out;
            }
            
            @keyframes pulse {
              0% {
                transform: scale(1);
                box-shadow: 0 0 0 0 ${is404 ? 'rgba(255, 183, 77, 0.4)' : 'rgba(74, 122, 255, 0.4)'};
              }
              
              50% {
                transform: scale(1.05);
                box-shadow: 0 0 0 10px ${is404 ? 'rgba(255, 183, 77, 0)' : 'rgba(74, 122, 255, 0)'};
              }
              
              100% {
                transform: scale(1);
                box-shadow: 0 0 0 0 ${is404 ? 'rgba(255, 183, 77, 0)' : 'rgba(74, 122, 255, 0)'};
              }
            }
          `}
        </style>
      </head>
      <body>
        <WebBlock color="var(--dark-blue)" id="homepage-error">
          <Flex align="center" justify="center" p="60px 20px" minHeight="100vh">
            <Box 
              style={{ 
                maxWidth: "700px", 
                borderRadius: "12px",
                background: "rgba(255, 255, 255, 0.9)",
                padding: "40px",
                boxShadow: "0 8px 30px rgba(0, 0, 0, 0.12)"
              }}
            >
              <Flex align="center" justify="center" mb="30px">
                <G2XLogo height={60} />
              </Flex>
              
              <Box 
                mb="30px" 
                p="25px" 
                style={{
                  borderRadius: "8px",
                  background: is404 ? "#FFF4E5" : "#F0F5FF",
                  border: `1px solid ${is404 ? "#FFB74D" : "#4A7AFF"}`,
                }}
              >
                <Flex align="center" mb="15px">
                  <Box 
                    mr="15px" 
                    className="pulse-animation"
                    style={{
                      borderRadius: "50%",
                      background: is404 ? "#FFB74D" : "#4A7AFF",
                      width: "40px",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      fontSize: "24px",
                      fontWeight: "bold"
                    }}
                  >
                    !
                  </Box>
                  <Heading as="h1" weight="bold" color={is404 ? "#E65100" : "#0D47A1"}>
                    {is404 ? "Page Not Found" : "Unable to retrieve data"}
                  </Heading>
                </Flex>
                
                <Text size="1" color={is404 ? "#E65100" : "#0D47A1"}>
                  {is404 
                    ? "Sorry, the page you are looking for could not be found." 
                    : "We're having trouble connecting to our services. Please try again or contact support if the problem persists."}
                </Text>
              </Box>
              
              {caught?.data && (
                <Box 
                  mb="25px" 
                  style={{ 
                    padding: "15px", 
                    background: "rgba(0,0,0,0.05)", 
                    borderRadius: "6px",
                    maxHeight: "200px",
                    overflow: "auto"
                  }}
                >
                  <Text size="4" color="#666" mb="5px">Error details:</Text>
                  <pre style={{ margin: 0, fontSize: "12px" }}>{caught.data}</pre>
                </Box>
              )}
              
              <Flex align="center" direction="column" justify="center" mt="25px">
                {!is404 && (
                  <Button 
                    onClick={handleTryAgain}
                    style={{ 
                      width: "100%",
                      padding: "14px",
                      background: "#25E685", 
                      color: "white",
                      border: "none",
                      borderRadius: "8px",
                      fontSize: "16px",
                      fontWeight: "600",
                      cursor: "pointer",
                      marginBottom: "20px",
                      boxShadow: "0 4px 6px rgba(74, 122, 255, 0.25)",
                      transition: "all 0.2s ease"
                    }}
                  >
                    Try Again
                  </Button>
                )}
                
                <Flex width="100%" justify="center" gap="30px">
                  <a 
                    href="/" 
                    style={{ 
                      textDecoration: "none",
                      color: "#25E685",
                      fontWeight: "500",
                      fontSize: "16px",
                      padding: "10px 15px"
                    }}
                  >
                    Go Home
                  </a>
                  
                  {!is404 && (
                    <a 
                      href="/logout" 
                      style={{ 
                        textDecoration: "none",
                        color: "#25E685",
                        fontWeight: "500",
                        fontSize: "16px",
                        padding: "10px 15px"
                      }}
                    >
                      Log out
                    </a>
                  )}
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </WebBlock>
        <Scripts />
      </body>
    </html>
  );
}
