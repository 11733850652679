export const LOADED_SCRIPTS: Set<string> = new Set();

export function loadScript(src: string, id: string): Promise<void> {
  return new Promise((resolve, reject) => {
    if (typeof window === 'undefined') {
      resolve();
      return;
    }
    
    // If script was already loaded, don't load again
    if (LOADED_SCRIPTS.has(id)) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.src = src;
    script.id = id;
    script.async = true;
    
    script.onload = () => {
      LOADED_SCRIPTS.add(id);
      resolve();
    };
    
    script.onerror = (error) => {
      reject(error);
    };

    document.head.appendChild(script);
  });
}