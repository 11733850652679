import { useFetcher, useLocation } from "@remix-run/react";
import { useCallback, useEffect, useState } from "react";
import { useRootLoaderData } from "~/root";
import type { ActivityItem } from "~/routes/resource.activity-tracker";

/**
 * This hook tracks user navigation through the application and maintains a history of visited pages.
 * It handles both anonymous and logged-in users differently:
 *
 * For logged-in users:
 * - History is stored in Redis (server-side) through the activity-tracker API
 * - History persists across devices and browsers
 * - Syncs with server on every page load
 *
 * For anonymous users:
 * - History is stored in localStorage (browser-only)
 * - History is limited to the current browser
 * - Maximum of 10 items are stored
 *
 * Features:
 * - Automatically tracks page visits
 * - Stores page title, URL, timestamp, and an icon for each visit
 * - Prevents duplicate entries
 * - Respects the canSeeRecentActivity feature flag
 *
 * Usage example:
 * ```
 * const { history, addHistoryItem, clearHistory } = useHistoryTracker();
 *
 * // Access history
 * console.log(history); // [{path: '/', title: 'Home', timestamp: 123...}, ...]
 *
 * // Manually add an item
 * addHistoryItem({ path: '/custom-page', title: 'Custom Page' });
 *
 * // Clear history
 * clearHistory();
 * ```
 */

/**
 * Represents a single visit to a page in the user's navigation history
 *
 * @property path - The URL path that was visited (e.g., '/dashboard')
 * @property title - User-friendly name of the page (e.g., 'Dashboard')
 * @property timestamp - When the page was visited (Unix timestamp)
 * @property icon - Emoji representing the page type (e.g., '📊' for dashboard)
 */
export interface HistoryItem {
  path: string;
  title: string;
  timestamp: number;
  icon?: string;
}

/**
 * Maps URL paths to their corresponding emoji icons
 * Used to make the history more visually appealing and easier to scan
 *
 * Example: '/dashboard' => '📊'
 */
const pathToIconMap: Record<string, string> = {
  "/": "🏠",
  "/opportunities": "🔍",
  "/agencies": "🏢",
  "/search": "🔎",
  "/events": "📅",
  "/fedciv": "🏛️",
  "/defense": "🪖",
  "/health": "🏥",
  "/settings/profile": "⚙️",
  "/bookmark": "🔖",
  "/dashboard": "📊",
  "/settings/billing": "💳",
  "/saved_searches": "📑",
  "/companies": "🏢",
  "/pipelines": "📊",
};

/**
 * Maps URL paths to human-readable titles
 * Converts technical URLs into user-friendly names
 *
 * Example: '/settings/profile' => 'Profile Settings'
 */
const pathToTitleMap: Record<string, string> = {
  "/": "Home",
  "/opportunities": "Opportunities",
  "/agencies": "Agencies",
  "/search": "Search Results",
  "/events": "Events",
  "/settings/profile": "Profile Settings",
  "/bookmark": "Bookmarks",
  "/fedciv": "FedCiv - Home",
  "/health": "Health - Home",
  "/defense": "Defense - Home",
  "/dashboard": "Dashboard",
  "/settings/billing": "Billing",
  "/saved_searches": "Saved Searches",
  "/companies": "Companies",
  "/pipelines": "Pipelines",
};

/**
 * Hook that tracks navigation history for the Recent Activity feature
 *
 * Features:
 * - Stores navigation history in localStorage
 * - Syncs history to Redis via an API endpoint
 * - Automatically tracks page visits
 * - Provides methods to add, clear, and access history
 * - Respects feature flag status
 *
 * @returns {Object} Object containing history items and methods to manage them
 * @returns {HistoryItem[]} history - Array of history items
 * @returns {Function} addHistoryItem - Function to manually add an item to history
 * @returns {Function} clearHistory - Function to clear all history
 */
export function useHistoryTracker() {
  const location = useLocation();
  const [history, setHistory] = useState<ActivityItem[]>([]);
  const fetcher = useFetcher<{ activities: ActivityItem[] }>();

  const { canSeeRecentActivity, isloggedin, user } = useRootLoaderData();

  /**
   * Converts a URL path into a user-friendly title
   *
   * Strategy:
   * 1. First checks the predefined pathToTitleMap
   * 2. If not found, generates a title from the URL:
   *    - Takes the last segment of the path
   *    - Replaces dashes/underscores with spaces
   *    - Capitalizes words
   *
   * Example: '/user-settings' => 'User Settings'
   */
  const getPageTitle = useCallback((path: string): string => {
    // First check our predefined map
    if (pathToTitleMap[path]) {
      return pathToTitleMap[path];
    }

    // Try to generate a title from the path
    const segments = path.split("/").filter(Boolean);
    if (segments.length) {
      // Capitalize the last segment and replace dashes/underscores with spaces
      const lastSegment = segments[segments.length - 1];
      return lastSegment.replace(/[-_]/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
    }

    return "Unknown Page";
  }, []);

  /**
   * Finds the most appropriate emoji icon for a given URL path
   *
   * Strategy:
   * 1. Checks for exact match in pathToIconMap
   * 2. If no exact match, looks for partial matches
   *    (e.g., '/dashboard/analytics' would match '/dashboard')
   * 3. Falls back to '📄' if no matches found
   */
  const getPageIcon = useCallback((path: string): string => {
    // First check if we have a predefined icon
    if (pathToIconMap[path]) {
      return pathToIconMap[path];
    }

    // Check if the path starts with any of our known paths
    for (const [knownPath, icon] of Object.entries(pathToIconMap)) {
      if (path.startsWith(knownPath) && knownPath !== "/") {
        return icon;
      }
    }

    // Default icon
    return "📄";
  }, []);

  /**
   * Adds a new page visit to the history
   *
   * For logged-in users:
   * - Updates local state for immediate UI feedback
   * - Sends the data to Redis via activity-tracker API
   * - Handles API failures gracefully
   * - Server handles deduplication and storage limits
   *
   * For anonymous users:
   * - Updates local state
   * - Saves to localStorage with 10-item limit
   * - Handles deduplication locally
   *
   * In both cases:
   * - Adds current timestamp
   * - Prevents duplicate entries
   * - Respects feature flag
   *
   * @param {Omit<ActivityItem, 'timestamp'>} item - The activity to add (timestamp is added automatically)
   */
  const addHistoryItem = useCallback(
    (item: Omit<ActivityItem, "timestamp">) => {
      if (!canSeeRecentActivity) return;

      const newItem = {
        ...item,
        timestamp: Date.now(),
      };

      if (isloggedin) {
        // Logged in users: Update Redis via API
        setHistory((prevHistory) => {
          const filteredHistory = prevHistory.filter((h) => h.path !== item.path);
          return [newItem, ...filteredHistory];
        });

        try {
          const activityJson = JSON.stringify(newItem);
          fetcher.submit({ activity: activityJson }, { method: "post", action: "/resource/activity-tracker" });
        } catch (error) {
          console.error("[ACTIVITY TRACKER] Failed to send activity to server:", error);
        }
      } else {
        // Anonymous users: Update localStorage
        setHistory((prevHistory) => {
          const filteredHistory = prevHistory.filter((h) => h.path !== item.path);
          const updatedHistory = [newItem, ...filteredHistory].slice(0, 10);
          localStorage.setItem("anonymous_history", JSON.stringify(updatedHistory));
          return updatedHistory;
        });
      }
    },
    [canSeeRecentActivity, isloggedin],
  );

  // Initialize history based on auth status
  useEffect(() => {
    if (!canSeeRecentActivity) return;

    if (isloggedin) {
      // Logged in users: Get history from Redis via API
      fetcher.load("/resource/activity-tracker");
    } else {
      // Anonymous users: Get history from localStorage
      const savedHistory = localStorage.getItem("anonymous_history");
      if (savedHistory) {
        try {
          setHistory(JSON.parse(savedHistory));
        } catch (error) {
          console.error("Failed to parse anonymous history:", error);
        }
      }
    }
  }, [canSeeRecentActivity, isloggedin]);

  // Track navigation changes
  useEffect(() => {
    if (!canSeeRecentActivity) return;
    const excludePaths = ["/access/login", "/access/signup"];

    // Check if current path is not in the excluded paths (like login/signup)
    if (!excludePaths.includes(location.pathname)) {
      // Get the current route information
      const path = location.pathname;
      const title = getPageTitle(path); // Convert path to user-friendly title
      const icon = getPageIcon(path); // Get appropriate emoji for this route

      // Only add to history if:
      // 1. History is empty (first visit), OR
      // 2. This is a different path than the most recent history entry
      // This prevents duplicate entries when user refreshes the page
      if (history.length === 0 || history[0]?.path !== path) {
        addHistoryItem({ path, title, icon });
      }
    }
  }, [location.pathname, canSeeRecentActivity]);

  /**
   * Clears the user's navigation history
   *
   * For anonymous users:
   * - Clears localStorage
   * - Resets local state
   *
   * For logged-in users:
   * - Only clears local state
   * - Server-side data persists (would need additional API endpoint to clear)
   */
  const clearHistory = useCallback(() => {
    if (!canSeeRecentActivity) return;

    setHistory([]);
    if (!isloggedin) {
      localStorage.removeItem("anonymous_history");
    }
  }, [canSeeRecentActivity, isloggedin]);

  return {
    history,
    addHistoryItem,
    clearHistory,
  };
}

export default useHistoryTracker;
